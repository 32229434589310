<template>
  <v-container>
    <v-flex>
      <v-img
        :src="require('@/assets/tulogo.png')"
        class="my-3"
        contain
        height="100"
        >
      </v-img>
    </v-flex>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        >
        <v-text-field
          ref="usuario"
          id="usuario"
          name="usuario"
          v-model="username"
          label="Usuario"
          dense outlined
          v-on:keyup="keymonitor"
          >
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        >
        <v-text-field
          v-model="password"
          label="Contrasena"
          ref="password"
          id="password"
          name="password"
          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPass ? 'text' : 'password'"
          prepend-inner-icon="mdi-lock"
          outlined
          clearable
          dense
          v-on:keyup="keymonitor"
          @click:append="showPass = !showPass"
          >
        </v-text-field>
      </v-col>
    </v-row>
    <div class="text-center">
      <v-btn
        to="/"
        class="ma-2"
        outlined
        color="indigo">
          Atras
      </v-btn>
      <v-btn
        class="ma-2"
        outlined
        @click.stop="checkLogin()"
        color="indigo">
          Iniciar
      </v-btn>
    </div>
  </v-container>
</template>
<script>
  export default {
    name: 'LoginForm',
    props: {
      //
    },
    components: {
      //
    },
    data: () => ({
      username: '',
      password: '',
      showPass: false,
    }),
    methods: {
      initialize: function () {
        //
      },
      loginOLD () {
        if ((this.username !== "" && this.username !== null) && 
            (this.password !== "" && this.password !== null)) {
          this.checkLogin();
        }
      },
      keymonitor: function (event) {
        if (event.key === 'Enter'){
          this.checkLogin();
        }
      },
      async checkLogin () {
        this.username = await this.myTrim(this.username);
        this.password = await this.myTrim(this.password);
        if (await this.username === '' && await this.password === '') {
          this.getFocus('usuario');
          this.$store.dispatch('loadMessage', { msg: 'Aviso: Ingresar Usuario', type: 'error' })
        }
        if (await this.username !== '' && await this.password === '') {
          this.getFocus('password');
          this.$store.dispatch('loadMessage', { msg: 'Aviso: Ingresar Contraseña', type: 'error' })
        }
        if (await this.username === '' && await this.password !== '') {
          this.getFocus('usuario');
          this.$store.dispatch('loadMessage', { msg: 'Aviso: Ingresar Usuario', type: 'error' })
        }
        if (this.username !== '' && this.password !== '') {
          let msg = '';
          let msgtype = '';
          let formData = await {
            email: this.username, 
            password: this.password,
            evento: ''
          };
          let apiRoute = await 'login';
          await this.setToken('');
          await this.$store.dispatch('loadTitle', '');
          const returnData = await true;
          let dataResponse = await this.callAPI(formData,apiRoute,this.token,returnData);
          if (await dataResponse.error){
            msgtype = await 'error';
            if(await dataResponse.error === 'invalid_credentials'){
              msg = await 'Aviso: Usuario o contraseña no es valida !!';
            } else {
              msg = await 'Aviso: acceso denegado ' + dataResponse.error;
            }
            await this.displayMsg(msg, msgtype);
          }
          if (await dataResponse.token){
            await this.setToken(dataResponse.token);
            await this.getTokenInfo();
            await this.createLog(this.userData.id, 'logUser');
          }
        }
      },
      processData: function () {
        //
      }
    },
    beforeCreate () {
      //
    },
    created () {
      setTimeout(() => {
        if (this.loggedIn === 0 && this.$route.name === 'Login') { 
            this.getFocus('usuario');
        }
      }, 200)
    },
    mounted () {
      this.setToken('');
      if(this.urlApi.includes('localhost')){
        this.username = 'user';
        this.password = 'admin';
      }
    },
    updated () {
      //
    },
    watch: {
      //
    },
    computed: {
      //
    }
  }
</script>
