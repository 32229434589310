<template>
  <div class="home">
    <LoginForm msg="Welcome to Your LoginForm App"/>
  </div>
</template>

<script>
import LoginForm from '@/components/LoginForm.vue'
  export default {
    name: 'Login',
    props: {
      //
    },
    components: {
      LoginForm
    },
    data: () => ({
      //
    }),
    methods: {
      initialize: function () {
        //
      },
      processData: function () {
        //
      }
    },
    created () {
      //
    },
    mounted () {
      //
    },
    updated () {
      //
    },
    watch: {
      //
    },
    computed: {
      //
    }
  }
</script>
